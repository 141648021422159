#div-resultados-qtdd {
    display: inline-block;
}

#topo-resultados-busca {
    height: 50px;
}

#tipo-ordenacao-resultados {
    border: 1px solid #82B2B9;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
    color: #2F7F8B;
    cursor: pointer;
    font-weight: bold;
    height: 25px;
    margin: 0;
    padding: 0 0;
    top: 10px;
}

#div-resultados-qtdd {
    top: 12px;
}

#topo-resultados-busca.visualizacao-grade {
    padding: 0 35px 0 15px;
}

#topo-resultados-busca.visualizacao-mapa {
    padding: 0 0 0 15px;
}

@media screen and (max-width:575px){
    #topo-resultados-busca.visualizacao-grade {
        padding:0;
    }
}