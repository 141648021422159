html{
    line-height:1.15;
    -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100%;
    scroll-behavior:smooth
}
iframe>*{
    margin-top:0!important;
    padding-top:0!important
}
blockquote,body,dd,dl,figure,h1,h2,h3,h4,h5,h6,hr,ol,p,pre,ul{
    margin:0;
    padding:0
}
body{
    font:400 14px/1.5 MontserratLight;
    color:#818181;
    background-color:#fff;
    -webkit-font-feature-settings:"kern" 1;
    -o-font-feature-settings:"kern" 1;
    font-feature-settings:"kern" 1;
    -webkit-font-kerning:normal;
    font-kerning:normal;
    box-sizing:border-box;
    overflow: hidden;
}
body *{
    box-sizing:inherit
}
p{
    font-size:16px
}
p.secundario{
    font-size:14px
}
blockquote,dl,figure,h1,h2,h3,h4,h5,h6,ol,p,pre,ul{
    margin-top:15px;
    margin-bottom:15px
}
.wrapper{
    max-width:1000px
}
.large-wrapper,.wrapper{
    margin-right:auto;
    margin-left:auto;
    padding-right:30px;
    padding-left:30px
}
.large-wrapper{
    max-width:1260px
}
@media screen and (max-width:576px){
    .large-wrapper{
        padding-right:15px;
        padding-left:15px
    }
}
.responsive-nowrap{
    max-width:100%
}
.responsive-nowrap::-webkit-scrollbar-track{
    background-color:transparent !important
}
.responsive-nowrap::-webkit-scrollbar{
    background:transparent !important
}
.responsive-nowrap::-webkit-scrollbar-thumb{
    background:transparent !important;
    border-radius:8px !important
}
@media screen and (max-width:576px){
    .responsive-nowrap{
        margin-bottom:10px;
        overflow-x:auto
    }
}
#topo-site{
    z-index:9999
}
@media screen and (max-width:800px){
    section.wrapper{
        padding-right:15px;
        padding-left:15px
    }
}

.large-wrapper:after,.wrapper:after{
    content:"";
    display:table;
    clear:both
}
img{
    max-width:100%;
    border-style:none;
    vertical-align:middle
}
article,aside,details,figcaption,figure,figure>img,footer,header,main,menu,nav,section{
    display:block
}
figure{
    margin:1em 40px
}
summary{
    display:list-item
}
hr{
    box-sizing:content-box;
    height:0;
    overflow:visible
}
figcaption{
    font-size:10.5px
}
code,kbd,pre,samp{
    font-family:monospace,monospace;
    font-size:1em
}
ol:not(.seamless),ul:not(.seamless){
    margin-left:30px
}
ol.seamless,ul.seamless{
    list-style:none
}
li>ol,li>ul{
    margin-bottom:0
}
h1,h2,h3,h4,h5,h6{
    font-weight:400;
    line-height:1.1
}
h1{
    font-family:MontserratMedium;
    color:#42949e;
    font-weight:400;
    font-size:35px
}
@media screen and (max-width:800px){
    h1{
        font-size:28px
    }
}
h2{
    font-family:MontserratMedium;
    color:#42949e;
    font-weight:400;
    font-size:28px
}
@media screen and (max-width:800px){
    h2{
        font-size:21px
    }
}
h3{
    font-family:MontserratMedium;
    color:#42949e;
    font-weight:400;
    font-size:25.66667px
}
@media screen and (max-width:800px){
    h3{
        font-size:18.66667px
    }
}
h4{
    font-family:MontserratMedium;
    color:#42949e;
    font-weight:400;
    font-size:24.5px
}
@media screen and (max-width:800px){
    h4{
        font-size:17.5px
    }
}
h5{
    font-family:MontserratMedium;
    color:#42949e;
    font-weight:400;
    font-size:23.8px
}
@media screen and (max-width:800px){
    h5{
        font-size:16.8px
    }
}
h6{
    font-family:MontserratMedium;
    color:#42949e;
    font-weight:400;
    font-size:23.33333px
}
@media screen and (max-width:800px){
    h6{
        font-size:16.33333px
    }
}
.btn-filtro,.checkbox-label .checkbox-custom,.checkbox-label .checkbox-custom:after,.checkbox-label input:checked~.checkbox-custom:before,.conteudo-central.info-empresa,.estrutura-resultados-mapa .empresa-dados,.floating-label .label-input,.floating-label input,.section-cases .controls svg path,.section-duvidas .pergunta svg,.section-eventos .thumb img,.section-sidenav,.section-sidenav.filtros-busca .componente-filtro .elemento-filtro .cbx-estilizado,.section-sidenav.filtros-busca .componente-filtro .lnk-limpar,.section-visao .controls svg path,.select-label-inside,:focus::-webkit-input-placeholder,[data-btn~=border-img] svg path,[data-btn~=fechar-interno] svg path,[data-btn~=generico] svg path,[data-mensagem] svg>path,a,button{
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    transform:translateZ(0);
    filter:none;
    transition:.3s ease-in-out
}
a{
    color:#818181;
    text-decoration:none;
    background-color:transparent;
    -webkit-text-decoration-skip:objects
}
a:active,a:focus,a:hover{
    text-decoration:underline;
    color:#000
}
abbr[title]{
    border-bottom:none;
    text-decoration:underline;
    -webkit-text-decoration:underline dotted;
    text-decoration:underline dotted
}
b,strong{
    font-weight:inherit;
    font-weight:bolder;
    font-family:MontserratSemiBold
}
dfn{
    font-style:italic
}
mark{
    background-color:#ff0;
    color:#818181
}
small{
    font-size:10.5px
}
sub,sup{
    font-size:8.4px;
    line-height:0;
    position:relative;
    vertical-align:baseline
}
sub{
    bottom:-.25em
}
sup{
    top:-.5em
}
audio,canvas,video{
    display:inline-block
}
audio:not([controls]){
    display:none;
    height:0
}
svg:not(:root){
    overflow:hidden
}
[hidden],template{
    display:none
}
blockquote{
    color:#c2c2c2;
    border-left:4px solid #f2f2f2;
    padding-left:15px;
    font-style:italic
}
blockquote>:last-child{
    margin-bottom:0
}
code,pre{
    font-size:15px;
    border:1px solid #f2f2f2;
    border-radius:3px;
    background-color:#eef
}
code{
    padding:1px 5px
}
pre{
    padding:8px 12px;
    overflow-x:auto
}
pre>code{
    border:0;
    padding-right:0;
    padding-left:0
}
.page-content{
    padding:30px 0
}
.page-content-2{
    padding-top:60px;
    padding-bottom:60px
}
@media screen and (max-width:600px){
    .page-content-2{
        padding-top:30px;
        padding-bottom:30px
    }
}
.page-content-3{
    padding-top:90px;
    padding-bottom:90px
}
@media screen and (max-width:600px){
    .page-content-3{
        padding-top:30px;
        padding-bottom:30px
    }
}
.page-heading{
    font-size:20px
}
.icon-colored>svg,.icon>svg{
    display:inline-block;
    width:1em;
    height:1em;
    vertical-align:middle
}
.icon>svg{
    color:inherit
}
.icon>svg path{
    fill:currentcolor
}
.center{
    text-align:center
}
.right{
    text-align:right
}
.italic{
    text-align:italic
}
.foto{
    width:100%;
    overflow:hidden
}
.foto img{
    width:100%;
    height:100%;
    -o-object-fit:cover;
    object-fit:cover
}
.brand-color{
    color:#42949e
}
@media screen and (min-width:801px){
    .medium-width{
        max-width:800px
    }
}
@media screen and (min-width:801px){
    .small-width{
        max-width:500px
    }
}
@media screen and (min-width:801px){
    .xsmall-width{
        max-width:400px
    }
}
.medium-text{
    font-size:12.18px
}
.small-text{
    font-size:10.5px
}
.ellipsis{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis
}
@media screen and (max-width:767px){
    .col-md-3{
        margin-bottom:0
    }
}
.page-template-soc-credenciado #rodape{
    background-color:#f2f2f2;
    color:#5c5c5c
}
.page-template-soc-credenciado #rodape a{
    color:#5c5c5c
}
.page-template-soc-credenciado #rodape #menu-rodape>li>a,.page-template-soc-credenciado #rodape .outros-links>li>a{
    color:#42949e
}
.page-template-soc-credenciado #rodape .assinatura{
    border-top:1px solid #f2f2f2
}
#div-carregando-operacao{
    background-color:hsla(0,0%,100%,.9);
    display:flex;
    height:100%;
    position:fixed;
    width:100%;
    z-index:9999
}
#div-carregando-operacao #div-infos-carregando-operacao{
    left:48%;
    position:relative;
    top:40%
}
#div-carregando-operacao #div-infos-carregando-operacao span{
    bottom:10px;
    color:#444;
    font-size:18px;
    font-weight:700;
    line-height:120%;
    position:relative;
    right:45px
}
#div-carregando-operacao #div-infos-carregando-operacao div.loader{
    animation:a 1.2s linear infinite;
    border:12px solid rgba(0,0,0,.1);
    border-left-color:#44949d;
    border-radius:50%;
    height:100px;
    width:100px
}
@keyframes a{
    to{
        transform:rotate(1turn)
    }
}
.filtro-km,button,input[type=date],input[type=datetime-local],input[type=datetime],input[type=email],input[type=month],input[type=number],input[type=password],input[type=range],input[type=search],input[type=tel],input[type=text],input[type=time],input[type=url],input[type=week],select,textarea{
    padding:10px;
    border:1px solid #c2c2c2;
    border-radius:4px;
    background-color:#fff;
    transition:.2s ease-in-out;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    outline:none;
    overflow:visible
}
.filtro-km:focus,button:focus,input:focus[type=date],input:focus[type=datetime-local],input:focus[type=datetime],input:focus[type=email],input:focus[type=month],input:focus[type=number],input:focus[type=password],input:focus[type=range],input:focus[type=search],input:focus[type=tel],input:focus[type=text],input:focus[type=time],input:focus[type=url],input:focus[type=week],select:focus,textarea:focus{
    border-color:#c2c2c2;
    box-shadow:0 2px 10px 0 rgba(66,148,158,.5)
}
input[type=date],input[type=datetime-local],input[type=datetime],input[type=email],input[type=month],input[type=number],input[type=password],input[type=range],input[type=search],input[type=tel],input[type=text],input[type=time],input[type=url],input[type=week],select,textarea{
    width:100%
}
button,input,optgroup,select,textarea{
    font:inherit;
    margin:0
}
button,select{
    text-transform:none
}
select{
    color:#c2c2c2
}
[type=reset],[type=submit],button,html [type=button]{
    -webkit-appearance:button
}
[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{
    border-style:none;
    padding:0
}
[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{
    outline:1px dotted ButtonText
}
fieldset{
    -webkit-margin-start:0;
    -webkit-margin-end:0;
    -webkit-padding-before:0;
    -webkit-padding-start:0;
    -webkit-padding-end:0;
    -webkit-padding-after:0;
    padding:0 0 30px;
    border:none
}
legend{
    box-sizing:border-box;
    color:inherit;
    display:table;
    max-width:100%;
    padding:0;
    white-space:normal
}
progress{
    display:inline-block;
    vertical-align:baseline
}
label[for]{
    display:block;
    font-size:.75em
}
input[invalid]{
    border:1px solid red
}
[type=checkbox],[type=radio]{
    box-sizing:border-box;
    padding:0
}
[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{
    height:auto
}
[type=search]{
    -webkit-appearance:textfield;
    outline-offset:-2px
}
[type=search]::-webkit-search-cancel-button,[type=search]::-webkit-search-decoration{
    -webkit-appearance:none
}
::-webkit-file-upload-button{
    -webkit-appearance:button;
    font:inherit
}
::-webkit-input-placeholder{
    color:#818181
}
:-moz-placeholder,::-moz-placeholder{
    color:#818181
}
:-ms-input-placeholder{
    color:#818181
}
:focus::-webkit-input-placeholder{
    opacity:0
}
.honeypot{
    display:none
}
button{
    padding-left:2em;
    padding-right:2em;
    text-transform:uppercase;
    background-color:#42949e;
    border:0;
    color:#fff;
    line-height:1.5;
    cursor:pointer
}
button:focus,button:hover{
    background-color:#42949e
}
button.clean{
    background-color:transparent!important;
    border:0;
    padding:10px
}
button.clean:active,button.clean:focus,button.clean:hover{
    background-color:transparent;
    box-shadow:none
}
button[data-btn]:not([data-btn~=border]){
    border:0;
    text-transform:none
}
button[data-btn]{
    text-transform:none
}
.select-label-inside{
    border:1px solid #c2c2c2;
    border-radius:4px;
    padding:5px;
    overflow:hidden;
    /* background-image:url(../../images/drop-arrow.png); */
    background-position:right 10px center;
    background-repeat:no-repeat
}
.select-label-inside label{
    margin-left:5px;
    position:absolute;
    top:5px
}
.select-label-inside select{
    border:none;
    padding:20px 0 0;
    width:120%;
    background-color:transparent
}
.select-label-inside select:focus{
    box-shadow:none
}
.select-label-inside:active,.select-label-inside:focus,.select-label-inside:hover{
    border:1px solid #42949e
}
.floating-label{
    margin-bottom:15px;
    position:relative
}
.floating-label .label-input{
    position:absolute;
    top:20px;
    left:20px;
    opacity:0
}
.floating-label input{
    border-radius:25px;
    padding:15px 20px;
    position:relative;
    font-size:12.18px
}
.floating-label input:focus{
    padding:22px 20px 8px
}
.floating-label input:focus+.label-input{
    transform:translateY(-10px);
    opacity:1
}
.input-upload{
    display:block;
    width:100%;
    margin-bottom:15px;
    border-radius:25px;
    padding:0 20px;
    border:1px solid #c2c2c2;
    background-color:#fff;
    transition:.2s ease-in-out;
    position:relative;
    min-height:40px
}
.input-upload .label{
    width:100%;
    height:100%;
    top:0;
    left:0;
    text-align:center;
    position:relative;
    font-size:12.18px
}
.input-upload input{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity:0
}
.combo-input{
    text-align:left
}
.combo-input small{
    text-align:left;
    display:block;
    font-size:12.18px;
    margin-top:10px
}
.combo-input .container-input{
    position:relative
}
.combo-input:not(:last-child){
    margin-bottom:15px
}
.combo-input label{
    color:#42949e;
    font-size:18px;
    font-family:Montserrat
}
.combo-input .icn-visualizar-senha{
    position:absolute;
    right:15px;
    margin-top:10px
}
.combo-input .icn-visualizar-senha svg{
    width:16px;
    height:16px;
    margin-bottom:-5px
}
.combo-input .icn-visualizar-senha svg>*{
    fill:#5c5c5c
}
.checkbox-label{
    display:inline-block;
    position:relative;
    margin:auto;
    cursor:pointer;
    font-size:22px;
    line-height:24px;
    height:24px;
    width:24px;
    clear:both;
    margin-bottom:-6px;
    margin-right:8px
}
.checkbox-label input{
    position:absolute;
    opacity:0;
    cursor:pointer
}
.checkbox-label .checkbox-custom{
    position:absolute;
    top:0;
    left:0;
    height:24px;
    width:24px;
    background-color:transparent;
    border-radius:4px;
    border:1px solid #c2c2c2
}
.checkbox-label input:checked~.checkbox-custom{
    background-color:#c2c2c2;
    border-radius:5px;
    transform:rotate(0deg) scale(1);
    opacity:1;
    border:2px solid #c2c2c2
}
.checkbox-label .checkbox-custom:after{
    position:absolute;
    content:"";
    left:12px;
    top:12px;
    height:0;
    width:0;
    border-radius:5px;
    border:solid #fff;
    border-width:0 3px 3px 0;
    transform:rotate(0deg) scale(0);
    opacity:1
}
.checkbox-label input:checked~.checkbox-custom:after{
    transform:rotate(45deg) scale(1);
    opacity:1;
    left:8px;
    top:3px;
    width:6px;
    height:12px;
    border:solid #fff;
    border-width:0 2px 2px 0;
    background-color:transparent;
    border-radius:0
}
.checkbox-label .checkbox-custom:before{
    position:absolute;
    content:"";
    left:10px;
    top:10px;
    width:0;
    height:0;
    border-radius:5px;
    border:2px solid #c2c2c2;
    transform:scale(0)
}
.checkbox-label input:checked~.checkbox-custom:before{
    left:-3px;
    top:-3px;
    width:24px;
    height:24px;
    border-radius:5px;
    transform:scale(3);
    opacity:0;
    z-index:999
}
.regular-input{
    width:80%
}
.medium-input{
    width:50%
}
.small-input{
    width:40%
}
.xsmall-input{
    width:30%
}
@media screen and (max-width:576px){
    .medium-input,.regular-input,.small-input,.xsmall-input{
        width:100%
    }
}
input:invalid:focus{
    border:1px solid red
}
[data-grid]>*{
    display:inline-block;
    flex:1 1 auto;
    min-width:0
}
[data-grid]{
    display:flex
}
[data-grid~=row]{
    flex-direction:row
}
[data-grid~=column]{
    flex-direction:column
}
[data-grid~=wrap]{
    flex-wrap:wrap
}
[data-grid~=nowrap]{
    flex-wrap:nowrap
}
[data-grid~=center]{
    justify-content:center
}
[data-grid~=start]{
    align-items:flex-start;
    align-content:flex-start;
    justify-content:flex-start
}
[data-grid~=end]{
    align-items:flex-end;
    align-content:flex-end;
    justify-content:flex-end
}
[data-grid~=justify]{
    justify-content:space-between
}
[data-grid~=around]{
    justify-content:space-around
}
[data-grid~=center]{
    align-items:center;
    -ms-flex-align:center;
    align-content:center
}
[data-grid~=stretch]{
    align-items:stretch
}
@media screen and (min-width:601px){
    [data-grid~=spacing]>:not(:last-child):not([data-cell~=order-1]){
        margin-right:30px
    }
    [data-grid~=spacing]>[data-cell~=order-1]{
        margin-left:30px
    }
}
@media screen and (max-width:600px){
    [data-grid~=spacing]>*{
        margin-bottom:15px
    }
    [data-grid~=spacing]>[data-cell~=order-1]{
        margin-right:0;
        margin-left:15px
    }
    [data-grid~=spacing][data-grid~=row]>*{
        margin-bottom:0
    }
    [data-grid~=spacing][data-grid~=row]>[data-cell~=order-1]{
        margin-right:0;
        margin-left:15px;
        order:1
    }
    [data-grid~=spacing][data-grid~=row]>:not(:last-child):not([data-cell~=order-1]){
        margin-right:15px
    }
}
@media screen and (min-width:601px){
    [data-grid~=small-spacing]>:not(:last-child):not([data-cell~=order-1]){
        margin-right:7.5px
    }
    [data-grid~=small-spacing]>[data-cell~=order-1]{
        margin-left:7.5px
    }
}
@media screen and (max-width:600px){
    [data-grid~=small-spacing]>*{
        margin-bottom:15px
    }
    [data-grid~=small-spacing]>[data-cell~=order-1]{
        margin-right:0;
        margin-left:15px
    }
    [data-grid~=small-spacing][data-grid~=row]>*{
        margin-bottom:0
    }
    [data-grid~=small-spacing][data-grid~=row]>[data-cell~=order-1]{
        margin-right:0;
        margin-left:15px;
        order:1
    }
    [data-grid~=small-spacing][data-grid~=row]>:not(:last-child):not([data-cell~=order-1]){
        margin-right:15px
    }
}
[data-cell~="1of2"]{
    flex-basis:50%
}
[data-cell~="1of3"]{
    flex-basis:33.333%
}
[data-cell~="1o5"]{
    flex-basis:20%
}
[data-cell~="1of4"]{
    flex-basis:25%
}
[data-cell~="3of4"]{
    flex-basis:75%
}
[data-cell~="2of3"]{
    flex-basis:66.666%
}
[data-cell~=nospace]{
    margin-left:0;
    margin-right:0
}
[data-cell~=shrink]{
    flex:0 1 auto
}
[data-cell~=center]{
    align-self:center
}
[data-cell~=start]{
    align-self:flex-start
}
@media screen and (max-width:600px){
    [data-cell~=start]{
        align-self:center
    }
}
[data-cell~=end]{
    align-self:flex-end
}
[data-cell~=stretch]{
    align-self:stretch
}
@media screen and (min-width:601px){
    [data-cell~=order-1]{
        order:1
    }
}
@media screen and (max-width:600px){
    [data-cell]{
        margin-left:0;
        margin-right:0
    }
    [data-cell~=nospace]{
        margin-bottom:0
    }
    [data-cell~=fill]{
        width:100%
    }
}
[data-grid][data-grid~=cols-2],[data-grid][data-grid~=cols-3],[data-grid][data-grid~=cols-4],[data-grid][data-grid~=cols-5]{
    flex-wrap:wrap
}
[data-grid][data-grid~=spacing][data-grid~=cols-2]>*,[data-grid][data-grid~=spacing][data-grid~=cols-3]>*,[data-grid][data-grid~=spacing][data-grid~=cols-4]>*,[data-grid][data-grid~=spacing][data-grid~=cols-5]>*{
    margin-bottom:30px
}
@media screen and (max-width:800px){
    [data-grid][data-grid~=spacing][data-grid~=cols-2]>*,[data-grid][data-grid~=spacing][data-grid~=cols-3]>*,[data-grid][data-grid~=spacing][data-grid~=cols-4]>*,[data-grid][data-grid~=spacing][data-grid~=cols-5]>*{
        margin-bottom:15px
    }
}
@media screen and (max-width:600px){
    [data-grid][data-grid~=spacing][data-grid~=cols-2]>*,[data-grid][data-grid~=spacing][data-grid~=cols-3]>*,[data-grid][data-grid~=spacing][data-grid~=cols-4]>*,[data-grid][data-grid~=spacing][data-grid~=cols-5]>*{
        margin-bottom:7.5px
    }
}
@media screen and (min-width:601px){
    [data-grid~=cols-2]>*{
        flex-basis:50%
    }
    [data-grid~=cols-2]>:not(:last-child):not([data-cell~=order-1]):nth-child(2n){
        margin-right:0
    }
    [data-grid~=cols-2][data-grid~=small-spacing]>*,[data-grid~=cols-2][data-grid~=spacing]>*{
        flex-basis:calc(50% - 7.5px)
    }
}
@media screen and (min-width:801px){
    [data-grid~=cols-2][data-grid~=small-spacing]>*,[data-grid~=cols-2][data-grid~=spacing]>*{
        flex-basis:calc(50% - 30px)
    }
}
@media screen and (min-width:801px){
    [data-grid~=cols-3]>*{
        flex-basis:33.333%
    }
    [data-grid~=cols-3]>:not(:last-child):not([data-cell~=order-1]):nth-child(3n){
        margin-right:0
    }
    [data-grid~=cols-3][data-grid~=spacing]>*{
        flex-basis:calc(33.333% - 20.01px)
    }
}
@media screen and (max-width:800px){
    [data-grid~=cols-3]>*{
        flex-basis:50%
    }
    [data-grid~=cols-3]>:not(:last-child):not([data-cell~=order-1]):nth-child(2n){
        margin-right:0
    }
    [data-grid~=cols-3][data-grid~=spacing]>*{
        flex-basis:calc(50% - 15px)
    }
}
@media screen and (min-width:601px){
    [data-grid~=cols-4]>*{
        flex-basis:25%
    }
    [data-grid~=cols-4]>:not(:last-child):not([data-cell~=order-1]):nth-child(4n){
        margin-right:0!important
    }
    [data-grid~=cols-4][data-grid~=spacing]>*{
        flex-basis:calc(20% - 7.5px)
    }
}
@media screen and (min-width:801px){
    [data-grid~=cols-4][data-grid~=spacing]>*{
        flex-basis:calc(20% - 15px)
    }
}
@media screen and (max-width:600px){
    [data-grid~=cols-5]>*{
        flex-basis:33.333%
    }
    [data-grid~=cols-5]>:not(:last-child):not([data-cell~=order-1]):nth-child(3n){
        margin-right:0!important
    }
    [data-grid~=cols-5][data-grid~=spacing]>*{
        flex-basis:calc(33.333% - 20.01px)
    }
}
@media screen and (min-width:601px){
    [data-grid~=cols-5]>*{
        flex-basis:20%
    }
    [data-grid~=cols-5]>:not(:last-child):not([data-cell~=order-1]):nth-child(5n){
        margin-right:0!important
    }
    [data-grid~=cols-5][data-grid~=spacing]>*{
        flex-basis:calc(20% - 30px)
    }
}
@media screen and (max-width:600px){
    [data-grid]:not([data-grid~=row]){
        flex-direction:column;
        height:auto
    }
    [data-grid]:not([data-grid~=row])>*{
        display:block;
        height:auto;
        flex-basis:auto
    }
    [data-grid]:not([data-grid~=row])>[data-grid]{
        display:flex
    }
}
[data-btn]{
    background-color:#42949e;
    border-radius:4px;
    color:#fff;
    padding:10px 30px
}
[data-btn]:active,[data-btn]:focus,[data-btn]:hover{
    color:#fff;
    text-decoration:none;
    box-shadow:0 3px 8px 0 rgba(0,0,0,.5);
    transform:translateY(-4px);
    background-color:#42949e
}
[data-btn~=icon]{
    border-radius:50%;
    padding:11px 15px 14px!important
}
[data-btn~=icon] svg{
    width:15px;
    height:15px
}
[data-btn~=icon] svg path{
    fill:#fff
}
[data-btn~=icon].transform{
    transform:rotate(180deg)
}
[data-btn~=border]{
    background-color:transparent;
    border:2px solid #42949e;
    color:#42949e
}
[data-btn~=border]:active,[data-btn~=border]:focus,[data-btn~=border]:hover{
    border:2px solid #42949e
}
[data-btn~=border-img]{
    background-color:transparent;
    border:1px solid #42949e!important;
    color:#42949e;
    border-radius:4px
}
[data-btn~=border-img] svg{
    width:15px;
    height:15px;
    margin-right:10px
}
[data-btn~=border-img] svg path{
    fill:#42949e
}
[data-btn~=border-img]:active,[data-btn~=border-img]:focus,[data-btn~=border-img]:hover{
    background-color:transparent;
    border:1px solid #42949e!important;
    color:#42949e
}
[data-btn~=border-img]:active svg>path,[data-btn~=border-img]:focus svg>path,[data-btn~=border-img]:hover svg>path{
    fill:#42949e
}
[data-btn~=topo]{
    position:fixed;
    z-index:999;
    bottom:15px;
    right:15px
}
[data-btn~=generico]{
    border-radius:4px;
    line-height:normal;
    padding:10px 30px
}
[data-btn~=generico] svg{
    width:15px;
    height:15px;
    margin-right:10px
}
[data-btn~=generico] svg path{
    fill:#fff
}
[data-btn~=card-empresa]{
    width:100%
}
[data-btn~=fechar-video]{
    background-color:transparent;
    right:30px;
    padding:0;
    position:absolute;
    top:-10px;
    cursor:pointer
}
[data-btn~=fechar-video]:active,[data-btn~=fechar-video]:focus,[data-btn~=fechar-video]:hover{
    background-color:transparent;
    box-shadow:none
}
[data-btn~=fechar-video] svg{
    width:30px;
    height:30px
}
[data-btn~=fechar-interno]{
    background-color:transparent;
    right:50px;
    padding:0;
    position:absolute;
    top:65px;
    cursor:pointer
}
[data-btn~=fechar-interno]:active,[data-btn~=fechar-interno]:focus,[data-btn~=fechar-interno]:hover{
    background-color:transparent;
    box-shadow:none;
    transform:none
}
[data-btn~=fechar-interno]:active svg>path,[data-btn~=fechar-interno]:focus svg>path,[data-btn~=fechar-interno]:hover svg>path{
    fill:#42949e
}
[data-btn~=fechar-interno] svg{
    width:15px;
    height:15px
}
[data-btn~=fechar-interno] svg path{
    fill:#000
}
@media screen and (max-width:576px){
    [data-btn~=fechar-interno]{
        right:20px;
        top:60px
    }
}
[data-btn~=rede]{
    position:fixed;
    z-index:999;
    bottom:15px;
    right:15px
}
.btn-topo{
    background-color:#c2c2c2;
    width:60px;
    height:60px;
    border-radius:100%;
    position:fixed;
    bottom:30px;
    right:30px;
    line-height:60px
}
.btn-topo svg{
    width:15px
}
.btn-topo svg path{
    fill:#fff
}
.btn-estreito{
    padding:5px!important
}
.btn-alinhado{
    width:100%
}
.btn-limpo-estilizado{
    background-color:transparent!important;
    border-left:1px solid #c2c2c2;
    color:#42949e;
    height:30px!important;
    line-height:0!important;
    margin-top:5px;
    padding:10px;
    text-transform:none!important
}
.btn-limpo-estilizado:not(:disabled):hover::before, 
.btn-limpo-estilizado:not(:disabled):hover::after{
    visibility: hidden;
}
.btn-limpo-estilizado:focus{
    box-shadow:none!important
}
@media screen and (max-width:1366px){
    .btn-limpo-estilizado{
        font-size: 12px !important;
    }
}

[data-slider]{
    min-height:260px
}
.fade{
    -webkit-animation-name:b;
    -webkit-animation-duration:1.5s;
    animation-name:b;
    animation-duration:1.5s
}
@keyframes b{
    0%{
        opacity:.4
    }
    to{
        opacity:1
    }
}
.slider-dots{
    text-align:center
}
.slider-dots .slider-dot{
    cursor:pointer;
    height:15px;
    width:15px;
    margin:0 2px;
    background-color:#c2c2c2;
    border:1px solid #c2c2c2;
    border-radius:50%;
    display:inline-block;
    transition:background-color .6s ease
}
.slider-dots .slider-dot.active,.slider-dots .slider-dot:focus,.slider-dots .slider-dot:hover{
    background-color:#fff;
    border:1px solid #c2c2c2
}
[data-next],[data-prev]{
    cursor:pointer
}
[data-modal]{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:9999;
    margin:auto;
    background-color:rgba(0,0,0,.6);
    animation:c .3s ease-in-out forwards;
    padding:60px
}
@media screen and (max-width:800px){
    [data-modal]{
        padding:30px
    }
}
[data-modal]>*{
    position:relative;
    padding:45px 30px 0
}
[data-modal]>* .modal-content{
    border-radius:4px!important
}
@media screen and (max-width:600px){
    [data-modal]>*{
        padding:0;
        padding-top:40px
    }
}
[data-modal] .lightbox-img{
    max-height:80vh
}
@media screen and (max-width:600px){
    [data-modal] .lightbox-prev{
        position:absolute;
        left:0;
        padding-top:80px
    }
}
@media screen and (max-width:600px){
    [data-modal] .lightbox-next{
        position:absolute;
        right:0;
        padding-top:80px
    }
}
[data-modal] .modal-content{
    max-height:75vh;
    background-color:#fff;
    box-shadow:0 5px 30px 0 rgba(0,0,0,.8)
}
[data-modal] .modal-content .video-container{
    width:560px;
    max-width:100%
}
@media screen and (min-width:601px){
    [data-modal] .modal-content .video-container{
        width:860px;
        min-height:515px
    }
}
[data-modal].pointer{
    cursor:pointer
}
[data-modal] [data-btn~=fechar]{
    position:absolute;
    background-color:transparent;
    top:7.5px;
    right:30px;
    z-index:9999;
    margin:0;
    padding:0;
    line-height:1;
    color:#f2f2f2;
    box-shadow:none;
    cursor:pointer
}
[data-modal] [data-btn~=fechar] svg{
    width:25px;
    height:25px;
    color:inherit
}
[data-modal] [data-btn~=fechar] svg *{
    fill:currentcolor
}
@media screen and (max-width:600px){
    [data-modal] [data-btn~=fechar]{
        right:0
    }
}
[data-modal~=video] .modal-content{
    line-height:0
}
[data-modal~=inline] .modal-content{
    max-width:350px
}
[data-modal~=inline]:not(:target):not(.target){
    display:none;
    opacity:0
}
[data-modal~=inline] button{
    display:block;
    width:100%
}
[data-modal~=curriculo] .modal-content,[data-modal~=share] .modal-content{
    max-width:350px
}
[data-modal][hidden]{
    display:none
}
[data-modal~=transparent]>*{
    background-color:transparent;
    box-shadow:none
}
[data-modal~=text] .modal-content{
    padding:30px;
    width:640px;
    max-width:100%;
    overflow-y:auto;
    scrollbar-width: thin;
}
[data-modal~=text] .modal-content button{
    margin-top:15px;
    margin-bottom:0;
    display:inline-block
}
@media screen and (max-width:576px){
    [data-modal~=text]{
        text-align:left
    }
}
[data-modal~=slide]>*{
    animation:d .6s ease-in-out forwards
}
[data-modal~=fast]{
    animation-duration:.1s
}
@media screen and (max-width:800px){
    [data-modal] iframe{
        max-width:220px;
        max-height:420px
    }
}
@keyframes c{
    0%{
        opacity:0
    }
    to{
        opacity:1
    }
}
@keyframes d{
    0%{
        transform:translateY(-100%);
        opacity:0
    }
    to{
        transform:translateY(0);
        opacity:1
    }
}
[data-file-display] p{
    display:inline-block;
    vertical-align:top
}
[data-file-display] .ellipsis{
    width:90%
}
[data-file-display] [data-remove-single]{
    cursor:pointer
}
[data-file-display] [data-remove-single] svg{
    width:15px;
    height:15px
}
[data-file-display] [data-remove-single] svg path{
    fill:#42949e
}
[data-file-display] [data-remove-single] svg:active path,[data-file-display] [data-remove-single] svg:focus path,[data-file-display] [data-remove-single] svg:hover path{
    fill:#42949e
}
.busca-geral{
    background-color:#fff;
    border-radius:4px;
    height:40px;
    margin:0!important
}
.busca-geral>*{
    height:40px
}
.busca-geral.row{
    padding:0
}
.busca-geral>div {
    flex: auto;
}
@media screen and (max-width:576px){
    .busca-geral.row{
        padding:10px
    }
}
.busca-geral .left-buttons{
    padding:0 10px
}
@media screen and (min-width:575px){
    .busca-geral .left-buttons{
        border-right:1px solid #c2c2c2
    }
}
.busca-geral .left-buttons p{
    margin-top:10px;
    margin-bottom:10px
}
@media screen and (max-width:575px){
    .busca-geral .left-buttons p{
        margin-top:0
    }
}
.busca-geral .left-buttons [data-pilula]{
    display:inline-block
}
@media screen and (max-width:575px){
    .busca-geral .left-buttons [data-pilula]{
        width:100%
    }
}
.busca-geral input{
    border:none;
    border-radius:0;
    font-family:Montserrat
}
.busca-geral input:focus{
    border:none;
    box-shadow:none
}
.busca-geral input[invalid]{
    background-color:rgba(255,0,0,.2)!important
}
@media screen and (max-width:575px){
    .busca-geral input{
        border:1px solid #f2f2f2;
        margin-bottom:10px
    }
}
.busca-geral .row:hover >.excluir{
    display:inline
}
.busca-geral .excluir{
    cursor:pointer;
    display:none;
    padding:10px
}
.busca-geral .excluir svg{
    height:10px;
    vertical-align: initial;
    width:10px
}
.busca-geral .excluir svg path{
    fill:#c2c2c2
}
.busca-geral .excluir:hover{
    display:inline
}
.busca-geral button{
    cursor:pointer;
    text-transform:capitalize;
    font-family:MontserratMedium;
    background-color:#42949e;
    top: -1px;
}
.busca-geral button:active,.busca-geral button:focus,.busca-geral button:hover{
    background-color:#42949e;
}
@media screen and (min-width:575px){
    .busca-geral button{
        border-top-left-radius:0;
        border-bottom-left-radius:0
    }
}
.busca-geral button svg{
    height:15px;
    width:15px !important;
}
.busca-geral button[disabled]{
    background-color:#c2c2c2
}
@media screen and (min-width:577px){
    .busca-geral button{
        transform:translate(1px)
    }
}
@media screen and (max-width:575px){
    .busca-geral{
        padding:15px
    }
}
.busca-geral.busca-secundaria{
    border:1px solid #dad9d9
}
@media screen and (max-width:575px){
    .busca-geral.busca-secundaria{
        background-color:transparent;
        border:none;
        padding:0
    }
}
.busca-geral.busca-secundaria .left-buttons{
    border:none;
    padding:0 10px
}
@media screen and (max-width:575px){
    .busca-geral.busca-secundaria .left-buttons{
        display:none
    }
}
.busca-geral.busca-secundaria input{
    background-color:transparent
}
@media screen and (max-width:575px){
    .busca-geral.busca-secundaria input{
        background-color:#fff;
        margin-bottom:0;
        /* background-image:url(../../images/search.svg); */
        background-repeat:no-repeat;
        background-position:left 15px center;
        padding-left:50px
    }
}
.busca-geral label{
    color:red;
    position:absolute;
    transform:translateY(-25px);
    opacity:1;
    z-index:-1;
    transition:.5s ease
}
.busca-geral label.hidden{
    transform:translateY(0);
    opacity:0
}
@media screen and (max-width:575px){
    .secundario{
        background-color:#e4e4e4;
        margin-left:-5px;
        margin-right:-2px;
        padding:8px 22px 8px 20px;
        width:-webkit-fill-available
    }
}

.pilula{
    display:inline-block;
    width:-webkit-max-content;
    width:-moz-max-content;
    width:max-content
}
.pilula .btn{
    border:1px solid #42949e;
    padding:5px 15px;
    font-size:12.18px;
    font-family:MontserratMedium;
    background-color:#fff;
    color:#42949e;
    cursor:pointer;
    display:inline-block
}
.pilula .btn svg{
    fill:#42949e
}
.pilula .btn:first-child{
    border-top-left-radius:4px;
    border-bottom-left-radius:4px
}
.pilula .btn:last-child{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px
}
.pilula .btn.active,.pilula .btn:active,.pilula .btn:focus,.pilula .btn:hover{
    color:#fff;
    text-decoration:none;
    background-color:#42949e
}
.pilula .btn.active svg,.pilula .btn:active svg,.pilula .btn:focus svg,.pilula .btn:hover svg{
    fill:#fff
}
.pilula.pilula-busca{
    margin:0 0 15px -15px;
    padding:0;
}
.pilula.pilula-busca .btn{
    font-size:12.18px;
    font-family:Montserrat
}

.busca-geral .pilula.pilula-busca{
    margin: 4px 0 0 -4px;
}
@media screen and (max-width:576px){
    .pilula.pilula-busca{
        margin-bottom:15px;
        margin-top:0
    }
    .pilula.pilula-busca .btn{
        background-color:#fff;
        color:#42949e;
        border:1px solid #42949e;
        font-size:14px;
        width:50%;
        display:inline-block;
        padding:5px
    }
    .pilula.pilula-busca .btn.active,.pilula.pilula-busca .btn:active,.pilula.pilula-busca .btn:focus,.pilula.pilula-busca .btn:hover{
        color:#fff;
        background-color:#42949e
    }
}
@media screen and (max-width:418px){
    .pilula.pilula-busca .btn{
        padding:5px 8%
    }
}
.pilula.pilula-padrao .btn{
    height:40px;
    min-width:60px;
    padding-right:22px;
    padding-left:22px
}
@media screen and (max-width:575px){
    .pilula.pilula-padrao .btn{
        width:50%
    }
}
.pilula.pilula-padrao .btn svg{
    height:22px;
    position:relative;
    top:3px;
    vertical-align:text-bottom;
    width: 14px;
}
.pilula.pilula-padrao .btn span{
    display:none
}
@media screen and (max-width:575px){
    .pilula.pilula-padrao .btn span{
        display:inline-block;
        font-size:14px;
        margin-left:10px
    }
}
@media screen and (max-width:575px){
    .pilula.pilula-padrao{
        width:100%
    }
}
.alinha-direita{
    float:right
}
@media screen and (max-width:575px){
    .alinha-direita{
        float:none
    }
}
@media screen and (max-width:576px){
    [data-hidden=false]{
        display:block
    }
    [data-hidden=true]{
        display:none
    }
}
.toggle-grande{
    max-width:100%!important;
    padding:0 0 15px!important
}
[data-tooltip-big-left]:after,
[data-tooltip-big-left]:before,
[data-tooltip-big]:after,
[data-tooltip-big]:before,
[data-tooltip-bottom-big]:after,
[data-tooltip-bottom-big]:before,
[data-tooltip-bottom-left]:after,
[data-tooltip-bottom-left]:before,
[data-tooltip-btn-buscar]:disabled:after,
[data-tooltip-btn-buscar]:disabled:before,
[data-tooltip-left]:after,
[data-tooltip-left]:before,
[data-tooltip]:after,
[data-tooltip]:before {
    position:absolute;
    visibility:hidden;
    opacity:0;
    pointer-events:none;
    z-index:999;
    font-family:Montserrat;
    font-weight:400
}
[data-tooltip-big-left]:before,
[data-tooltip-big]:before,
[data-tooltip-bottom-big]:before,
[data-tooltip-bottom-left]:before,
[data-tooltip-btn-buscar]:disabled:before,
[data-tooltip-left]:before,
[data-tooltip]:before{
    border-radius:3px;
    background-color:#000;
    background-color:rgba(51,51,51,.9);
    color:#fff;
    padding:7px;
    text-align:center;
    font-size:14px;
    line-height:1.2
}
[data-tooltip-big-left]:after,
[data-tooltip-big]:after,
[data-tooltip-bottom-big]:after,
[data-tooltip-bottom-left]:after,
[data-tooltip-btn-buscar]:disabled:after,
[data-tooltip-left]:after,
[data-tooltip]:after{
    width:0;
    border-top:5px solid #000;
    border-top:5px solid rgba(51,51,51,.9);
    border-right:5px solid transparent;
    border-left:5px solid transparent;
    content:" ";
    font-size:0;
    line-height:0
}
[data-tooltip-big]:before{
    margin-left:-175px;
    width:350px
}
[data-tooltip-big-left]:hover:after,
[data-tooltip-big-left]:hover:before,
[data-tooltip-big]:hover:after,
[data-tooltip-big]:hover:before,
[data-tooltip-bottom-big]:hover:after,
[data-tooltip-bottom-big]:hover:before,
[data-tooltip-bottom-left]:hover:after,
[data-tooltip-bottom-left]:hover:before,
[data-tooltip-btn-buscar]:disabled:hover:after,
[data-tooltip-btn-buscar]:disabled:hover:before,
[data-tooltip-left]:hover:after,
[data-tooltip-left]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:hover:before{
    visibility:visible;
    opacity:1
}
[data-tooltip]{
    position:relative;
    z-index:2;
    cursor:pointer
}
[data-tooltip]:hover{
    z-index:999
}
[data-tooltip]:before{
    bottom:120%;
    left:50%;
    content:attr(data-tooltip);
    margin-bottom:5px;
    margin-left:-80px;
    width:160px
}
[data-tooltip]:after{
    bottom:120%;
    left:50%;
    margin-left:-5px
}
[data-tooltip-big]{
    position:relative;
    z-index:2;
    cursor:pointer
}
[data-tooltip-big]:hover{
    z-index:999
}
[data-tooltip-big]:before{
    bottom:120%;
    left:50%;
    content:attr(data-tooltip-big);
    margin-bottom:5px
}
[data-tooltip-big]:after{
    bottom:120%;
    left:50%;
    margin-left:-5px
}
[data-tooltip-left]{
    position:relative;
    z-index:2;
    cursor:pointer
}
[data-tooltip-left]:hover{
    z-index:999
}
[data-tooltip-left]:before{
    bottom:120%;
    left:0;
    content:attr(data-tooltip-left);
    margin-bottom:5px;
    margin-left:0;
    max-width:160px;
    width:100%
}
[data-tooltip-left]:after{
    bottom:120%;
    left:80px;
    margin-left:-5px
}
[data-tooltip-big-left]{
    position:relative;
    z-index:2;
    cursor:pointer
}
[data-tooltip-big-left]:hover{
    z-index:999
}
[data-tooltip-big-left]:before{
    bottom:120%;
    left:0;
    content:attr(data-tooltip-big-left);
    margin-bottom:5px;
    margin-left:-175px;
    width:350px
}
[data-tooltip-big-left]:after{
    bottom:120%;
    left:50%;
    margin-left:-5px
}
[data-tooltip-bottom-big]{
    position:relative;
    z-index:2;
    cursor:pointer
}
[data-tooltip-bottom-big]:hover{
    z-index:999
}
[data-tooltip-bottom-big]:before{
    top:120%;
    left:50%;
    content:attr(data-tooltip-bottom-big);
    margin-bottom:5px;
    margin-left:0;
    width:350px
}
[data-tooltip-bottom-big]:after{
    top:105%;
    left:80px;
    margin-left:-5px;
    transform:rotate(-180deg)
}
[data-tooltip-bottom-left]{
    position:relative;
    z-index:2;
    cursor:pointer
}
[data-tooltip-bottom-left]:hover{
    z-index:999
}
[data-tooltip-bottom-left]:before{
    top:120%;
    left:0;
    content:attr(data-tooltip-bottom-left);
    margin-bottom:5px;
    margin-left:0;
    min-width:160px;
    max-width: 50%;
}
[data-tooltip-bottom-left]:after{
    top:110%;
    left:80px;
    margin-left:-5px;
    transform:rotate(-180deg)
}
[data-tooltip-btn-buscar]:disabled{
    position:relative;
    z-index:2;
    cursor:pointer
}
[data-tooltip-btn-buscar]:disabled:hover{
    z-index:999
}
[data-tooltip-btn-buscar]:disabled:before{
    top:120%;
    right:0;
    content:attr(data-tooltip-btn-buscar);
    margin-bottom:5px;
    margin-left:0;
    width:200px
}
[data-tooltip-btn-buscar]:disabled:after{
    top:110%;
    left:50%;
    margin-left:-5px;
    transform:rotate(-180deg)
}
.section-dark,.section-desenvolvimento,.section-light,.section-tecnologias,.section-vagas,.section-visao{
    padding:90px 0
}
@media screen and (max-width:600px){
    .section-dark,.section-desenvolvimento,.section-light,.section-tecnologias,.section-vagas,.section-visao{
        padding:30px 0
    }
}
.section-crescer{
    background-image:linear-gradient(90deg,#a6ce39,#01994c);
    position:relative
}
.section-crescer>*,.section-crescer h1{
    color:#fff
}
.section-crescer .wrapper{
    height:330px
}
.section-crescer .wrapper [data-cell~="1of2"]:nth-child(2){
    line-height:0
}
.section-crescer:after{
    content:"";
    position:absolute;
    /* background-image:url(../../images/bg-trabalhe-conosco.png); */
    width:50%;
    height:100%;
    background-size:cover;
    top:0;
    bottom:0;
    right:0
}
.section-crescer .ilustrativa{
    position:relative;
    z-index:2
}
@media screen and (max-width:600px){
    .section-crescer .ilustrativa{
        display:none
    }
}
.section-crescer .conheca-empresa{
    position:absolute;
    left:50%;
    margin-left:-58.3px;
    bottom:-15px;
    z-index:5
}
@media screen and (max-width:600px){
    .section-crescer{
        padding:30px 0;
        background-image:linear-gradient(180deg,#a6ce39,#01994c);
        text-align:center
    }
    .section-crescer .wrapper{
        height:auto
    }
    .section-crescer:after{
        background-image:none
    }
    .section-crescer .conheca-empresa{
        margin-left:-22.5px
    }
    .section-crescer .conheca-empresa p{
        display:none
    }
}
.section-vagas h4{
    color:#818181
}
.section-vagas .conheca-box{
    border-radius:4px;
    padding:10px 30px;
    border:1px solid #c2c2c2;
    margin-bottom:30px
}
.section-vagas .conheca-box p{
    font-family:Montserrat;
    color:#42949e
}
@media screen and (max-width:600px){
    .section-vagas .conheca-box{
        padding:10px 15px
    }
}
.section-enviar-curriculo{
    background-color:#f2f2f2;
    padding:30px 0
}
@media screen and (max-width:600px){
    .section-enviar-curriculo{
        text-align:center
    }
}
.section-enviar-curriculo .descricao{
    font-family:Montserrat;
    color:#42949e
}
.section-empresa-lider{
    text-align:center
}
.section-empresa-lider .wrapper{
    border-bottom:1px solid #c2c2c2
}
.section-empresa-lider .item{
    margin-top:60px
}
@media screen and (max-width:800px){
    .section-empresa-lider .item{
        margin-top:30px
    }
}
.section-diferenciais{
    text-align:center
}
.section-diferenciais .wrapper{
    border-bottom:1px solid #c2c2c2
}
.section-diferenciais .item{
    margin-top:60px
}
.section-diferenciais .item span{
    color:#42949e;
    font-family:Montserrat;
    margin-top:15px
}
@media screen and (max-width:800px){
    .section-diferenciais .item{
        margin-top:30px
    }
}
.section-santos .wrapper{
    border-bottom:1px solid #c2c2c2
}
@media screen and (max-width:600px){
    .section-santos [data-btn]{
        display:block;
        text-align:center
    }
}
.section-visao{
    text-align:center
}
@media screen and (min-width:801px){
    .section-visao .slide p{
        max-width:600px
    }
}
@media screen and (max-width:600px){
    .section-visao .controls{
        display:none
    }
}
.section-cases .slide .caption,.section-visao .slide .caption{
    font-size:12.18px
}
.section-cases .slide .caption span,.section-visao .slide .caption span{
    display:block
}
.section-cases .slide .caption .caption,.section-visao .slide .caption .caption{
    max-width:200px
}
.section-cases .controls svg,.section-visao .controls svg{
    width:30px;
    height:30px
}
.section-cases .controls svg path,.section-visao .controls svg path{
    fill:#c2c2c2
}
.section-cases .controls svg:hover path,.section-visao .controls svg:hover path{
    fill:#42949e
}
.section-desenvolvimento{
    background-color:#f2f2f2
}
.section-desenvolvimento span{
    display:block
}
.section-desenvolvimento .container{
    margin-top:30px
}
@media screen and (max-width:600px){
    .section-desenvolvimento{
        text-align:center
    }
}
.section-beneficios .wrapper,.section-eventos .wrapper{
    border-bottom:1px solid #c2c2c2
}
.section-eventos .container{
    display:inline-block;
    margin-top:30px;
    width:unset
}
.section-eventos .thumb{
    width:100%;
    height:240px;
    display:block;
    background-color:#000;
    border-radius:6px;
    overflow:hidden;
    position:relative;
    cursor:pointer
}
.section-eventos .thumb img{
    width:100%;
    height:100%;
    -o-object-fit:cover;
    object-fit:cover;
    opacity:.6;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden
}
.section-eventos .thumb:after{
    /* content:url(../../images/play-video.png); */
    width:60px;
    height:60px;
    position:absolute;
    top:50%;
    left:50%;
    margin-top:-30px;
    margin-left:-30px
}
.section-eventos .thumb:hover img{
    opacity:.8
}
.section-projetos .wrapper{
    border-bottom:1px solid #c2c2c2
}
@media screen and (max-width:800px){
    .section-projetos{
        text-align:center
    }
}
.section-tecnologias .container{
    display:inline-block;
    margin-top:30px;
    width:unset
}
.section-tecnologias .logo{
    border:1px solid #c2c2c2;
    border-radius:6px;
    padding:15px 0
}
@media screen and (max-width:600px){
    .section-tecnologias .logo{
        padding:10px 15px
    }
}
.section-tecnologias .logo img{
    display:inline-block
}
@media screen and (max-width:800px){
    .section-tecnologias .logo img{
        width:50%
    }
}
@media screen and (max-width:600px){
    .section-tecnologias .logo img{
        -o-object-fit:contain;
        object-fit:contain;
        width:100%;
        height:100%
    }
}
.section-foto{
    height:80vh;
    /* background-image:url(../../images/ilustrativa-lounge.jpg); */
    background-size:cover;
    background-position:100%
}
@media screen and (max-width:800px){
    .section-foto{
        height:30vh
    }
}
.section-foto .wrapper{
    position:relative;
    height:100%
}
.section-foto .wrapper [data-btn]{
    position:absolute;
    right:0;
    bottom:-15px
}
.section-conheca-santos{
    background-image:linear-gradient(90deg,#bd4242,#f5ed5a);
    position:relative
}
.section-conheca-santos>*,.section-conheca-santos h1{
    color:#fff
}
.section-conheca-santos .wrapper{
    height:330px
}
.section-conheca-santos .wrapper [data-cell~="1of2"]:nth-child(2){
    line-height:0
}
.section-conheca-santos:after{
    content:"";
    position:absolute;
    /* background-image:url(../../images/bg-conheca-santos.png); */
    width:60%;
    height:100%;
    background-size:cover;
    top:0;
    bottom:0;
    right:0
}
.section-conheca-santos .ilustrativa{
    position:relative;
    z-index:2
}
@media screen and (max-width:600px){
    .section-conheca-santos .ilustrativa{
        display:none
    }
}
@media screen and (max-width:600px){
    .section-conheca-santos{
        padding:30px 0;
        background-image:linear-gradient(180deg,#bd4242,#f5ed5a);
        text-align:center
    }
    .section-conheca-santos .wrapper{
        height:auto
    }
    .section-conheca-santos:after{
        background-image:none
    }
}
.section-texto-foto .wrapper{
    border-bottom:1px solid #c2c2c2
}
@media screen and (max-width:600px){
    .section-texto-foto [data-btn]{
        display:block;
        text-align:center
    }
}
.section-texto-foto .wrapper{
    position:relative;
    height:100%
}
.section-descricao-vaga{
    background-image:linear-gradient(90deg,#397abe,#9bd2f5);
    position:relative
}
.section-descricao-vaga>*,.section-descricao-vaga h1{
    color:#fff
}
.section-descricao-vaga .wrapper{
    height:210px
}
.section-descricao-vaga .wrapper [data-cell~="1of2"]:nth-child(2){
    line-height:0
}
.section-descricao-vaga:after{
    content:"";
    position:absolute;
    /* background-image:url(../../images/bg-descricao-vaga.png); */
    width:60%;
    height:70%;
    background-size:cover;
    bottom:0;
    right:0
}
.section-descricao-vaga .ilustrativa{
    position:relative;
    z-index:2
}
@media screen and (max-width:600px){
    .section-descricao-vaga .ilustrativa{
        display:none
    }
}
@media screen and (max-width:600px){
    .section-descricao-vaga{
        padding:30px 0;
        background-image:linear-gradient(180deg,#a7d4f5,#3675b9);
        text-align:center
    }
    .section-descricao-vaga .wrapper{
        height:auto
    }
    .section-descricao-vaga:after{
        background-image:none
    }
}
.section-descricao-vaga button{
    cursor:pointer
}
[data-mensagem] p{
    color:#818181
}
[data-mensagem] svg{
    width:30px;
    height:30px
}
[data-mensagem] svg path{
    fill:#42949e
}
.section-requisitos .wrapper{
    padding-top:30px;
    padding-bottom:30px;
    border-bottom:1px solid #c2c2c2
}
.section-requisitos .wrapper .page-content{
    padding:15px 0
}
.section-candidate-se .wrapper{
    border-bottom:1px solid #c2c2c2
}
.section-candidate-se .content-form{
    min-width:100%
}
.section-candidate-se .content-form [data-curriculo],.section-candidate-se .content-form button{
    width:100%
}
@media screen and (min-width:601px){
    .section-candidate-se .content-form{
        height:476px
    }
    .section-candidate-se .content-form [data-curriculo]{
        width:450px
    }
}
.section-light h2{
    text-transform:none
}
.section-light h1{
    margin-top:0
}
@media screen and (max-width:576px){
    .section-light h1{
        line-height:37px
    }
}
.section-light b{
    font-family:Montserrat;
    font-size:18px;
    display:block;
    font-weight:400;
    color:#42949e;
    margin:10px 0
}
@media screen and (max-width:800px){
    .section-light h2{
        font-size:28px
    }
    .section-light h6{
        font-size:23.33333px
    }
}
.section-dark{
    background-color:#f2f2f2
}
.section-dark h2{
    text-transform:none
}
.section-dark h1{
    margin-top:0
}
@media screen and (max-width:800px){
    .section-dark h2{
        font-size:28px
    }
    .section-dark h6{
        font-size:23.33333px
    }
}
.section-dark b{
    font-size:18px;
    display:block;
    margin:10px 0
}
.section-topo-credenciado{
    background-image:linear-gradient(90deg,#005087,#1faeb2);
    padding:90px 0 0
}
.section-topo-credenciado h1{
    line-height:44px
}
.section-topo-credenciado h1,.section-topo-credenciado p{
    color:#fff
}
.section-topo-credenciado p{
    font-size:18px
}
@media screen and (max-width:576px){
    .section-topo-credenciado h1{
        text-align:center;
        font-size:23px;
        line-height:28px
    }
    .section-topo-credenciado p{
        text-align:center
    }
}
.section-topo-credenciado .ilustrativa svg{
    margin-bottom:-6px
}
@media screen and (min-width:577px){
    .section-topo-credenciado .busca-geral{
        margin-bottom:30px!important
    }
}
@media screen and (max-width:767px){
    .section-topo-credenciado{
        padding:90px 0 15px
    }
    .section-topo-credenciado .ilustrativa{
        display:none
    }
}
.section-como-funciona .etapas{
    margin-top:60px
}
.section-como-funciona .item{
    position:relative
}
.section-como-funciona .item .img{
    display:inline-block
}
.section-como-funciona .item .img-big{
    min-width:190px;
    min-height:190px;
    border-radius:4px
}
.section-como-funciona .item .img-big svg{
    width:190px;
    height:190px
}
@media screen and (max-width:670px){
    .section-como-funciona .item .img-big{
        max-width:100%
    }
}
.section-como-funciona .item:after{
    content:"";
    /* background-image:url(../../images/arrow-right.svg); */
    background-position:50%;
    background-repeat:no-repeat;
    min-width:40px;
    min-height:40px;
    border-radius:100%;
    position:absolute;
    right:-20px;
    top:50%;
    margin-top:-20px
}
@media screen and (max-width:1000px){
    .section-como-funciona .item:after{
        display:none
    }
}
.section-como-funciona .item:last-child:after{
    display:none
}
.section-como-funciona [data-btn=generico]{
    margin-top:60px
}
.section-vantagens .vantagens{
    margin-top:45px
}
.section-vantagens .vantagens .vantagem:first-child,.section-vantagens .vantagens .vantagem:nth-child(2),.section-vantagens .vantagens .vantagem:nth-child(3){
    margin-bottom:60px
}
.section-vantagens .vantagens .img svg{
    width:50px;
    height:50px
}
.section-vantagens .vantagens .img svg path{
    fill:#42949e
}
@media screen and (max-width:576px){
    .section-vantagens .vantagens .img{
        width:72px;
        height:72px;
        margin-bottom:15px
    }
}
@media screen and (min-width:577px){
    .section-vantagens .vantagens .descricao{
        padding-left:5px
    }
}
@media screen and (max-width:576px){
    .section-vantagens .vantagens .descricao{
        text-align:center
    }
}
.section-vantagens .vantagens .descricao b{
    margin:0;
    font-family:Montserrat;
    color:#42949e;
    font-weight:400
}
@media screen and (min-width:1201px){
    .section-vantagens .vantagens .descricao b{
        white-space:nowrap
    }
}
.section-vantagens .vantagens .descricao p{
    font-size:14px;
    margin:6px 0 0
}
@media screen and (min-width:577px){
    .section-maior-rede .pilula{
        display:none
    }
}
.section-duvidas h1,.section-maior-rede p{
    margin-bottom:30px
}
.section-duvidas p{
    font-size:12.18px
}
.section-duvidas b{
    display:inline-block;
    margin:0;
    font-family:Montserrat;
    font-size:18px;
    font-weight:400;
    color:#42949e
}
.section-duvidas .pergunta{
    margin-bottom:30px
}
.section-duvidas .pergunta svg{
    width:15px;
    height:15px;
    float:right
}
@media screen and (min-width:577px){
    .section-duvidas .pergunta svg{
        display:none
    }
}
.section-duvidas .pergunta .active svg{
    transform:rotate(-180deg)
}
.section-duvidas .pergunta p{
    font-size:12.18px;
    margin:5px 0
}
@media screen and (max-width:576px){
    .section-duvidas .pergunta{
        background-color:#f2f2f2;
        padding:15px;
        margin-left:-30px;
        margin-right:-30px;
        margin-bottom:10px
    }
    .section-duvidas .pergunta b{
        font-size:16px
    }
}
.section-duvidas .col-12:last-child .pergunta{
    margin-bottom:0
}
.section-duvidas .rodape{
    padding-top:60px
}
.section-socnet{
    background:linear-gradient(180deg,#005087,#1faeb2);
    padding:0
}
.section-socnet .inside-wrapper,.section-socnet .inside-wrapper h6{
    color:#fff
}
@media screen and (max-width:576px){
    .section-socnet .inside-wrapper h6{
        font-size:30px;
        line-height:37px
    }
}
.section-socnet .inside-wrapper [data-btn]{
    margin-top:10px
}
.section-socnet .ilustrativa{
    width:100%;
    height:318px
}
.section-socnet .ilustrativa svg{
    height:90%;
    margin-top:5%
}
.section-socnet .ilustrativa img{
    width:100%;
    height:100%;
    -o-object-fit:cover;
    object-fit:cover;
    -o-object-position:center;
    object-position:center
}
.section-socnet button{
    width:auto
}
@media screen and (max-width:576px){
    .section-socnet{
        text-align:center
    }
    .section-socnet .ilustrativa{
        display:none
    }
    .section-socnet .inside-wrapper{
        padding:30px 30px 60px
    }
}
.section-cases{
    position:relative
}
.section-cases .logo img{
    width:100%;
    height:100%;
    -o-object-fit:cover;
    object-fit:cover
}
.section-cases .btn-case{
    margin-top:15px
}
.section-cases p{
    margin:0 30px!important
}
@media screen and (max-width:576px){
    .section-cases p{
        margin:0 15px!important;
        line-height:20px
    }
}
.section-cases button.clean{
    padding:0
}
@media screen and (max-width:576px){
    .section-cases button.clean{
        position:absolute;
        bottom:30px
    }
    .section-cases button.clean[data-next]{
        right:15px
    }
}
.section-cases .slider-dots{
    margin-top:30px
}
.section-parceiros-destaque h1{
    margin-bottom:30px
}
.section-parceiros-destaque .nome-empresa{
    white-space:unset!important
}
.section-sidenav{
    display:inline-block;
    height:100vh;
    scrollbar-width: thin;
    overflow-y:auto;
    position:absolute;
    width:25%;
    border-right:1px solid #c2c2c2
}
.section-sidenav.filtros-busca{
    background-color:#f2f2f2;
    padding:80px 40px 0
}
.section-sidenav.filtros-busca .titulo-filtros{
    display:none;
    font-size:24px;
    margin-bottom:30px
}
.section-sidenav.filtros-busca .titulo-filtros .acao-fechar{
    cursor:pointer;
    float:right;
    margin-right:15px
}
.section-sidenav.filtros-busca .titulo-filtros .acao-fechar svg{
    height:15px
}
.section-sidenav.filtros-busca .titulo-filtros .acao-fechar svg path{
    fill:#000
}
@media screen and (max-width:576px){
    .section-sidenav.filtros-busca .titulo-filtros{
        display:block
    }
}
.section-sidenav.filtros-busca .componente-filtro{
    color:#494949;
    font-size:14px;
    margin-bottom:30px
}
.section-sidenav.filtros-busca .componente-filtro .tipo-filtro{
    font-family:MontserratSemiBold;
    margin-bottom:16px;
    color:#5c5c5c;
    text-transform:uppercase;
    font-size:12px
}
.section-sidenav.filtros-busca .componente-filtro .lnk-limpar{
    color:#5c5c5c;
    cursor:pointer;
    float:right
}
.section-sidenav.filtros-busca .componente-filtro .lnk-limpar:hover{
    color:#42949e
}
.section-sidenav.filtros-busca .componente-filtro .container-filtros.collapsed{
    max-height:10.2em;
    overflow:hidden;
    position:relative
}
.section-sidenav.filtros-busca .componente-filtro .expand-filtros{
    color:#42949e;
    cursor:pointer;
    display:block;
    font-family:MontserratSemiBold;
    font-size:14px;
    margin-top:10px;
    text-transform:none
}
.section-sidenav.filtros-busca .componente-filtro .elemento-filtro{
    margin-top:6px
}
.section-sidenav.filtros-busca .componente-filtro .elemento-filtro input[type=checkbox]{
    display:none
}
.section-sidenav.filtros-busca .componente-filtro .elemento-filtro input[type=checkbox]:checked+.cbx-estilizado{
    background-color:#42949e;
    border:1px solid #42949e
}
.section-sidenav.filtros-busca .componente-filtro .elemento-filtro input[type=checkbox]:checked+.cbx-estilizado:after{
    border:solid #fff;
    border-width:0 2px 2px 0;
    content:"";
    display:block;
    height:10px;
    left:9px;
    position:absolute;
    top:5px;
    transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
    -ms-transform:rotate(45deg);
    width:5px
}
.section-sidenav.filtros-busca .componente-filtro .elemento-filtro .cbx-estilizado{
    background-color:#fff;
    border:1px solid #c5c5c5;
    border-radius:4px;
    height:24px;
    position:absolute;
    width:24px
}
.section-sidenav.filtros-busca .componente-filtro .elemento-filtro span:not(.cbx-estilizado){
    margin-left:35px;
    vertical-align:middle;
    display:inline-block
}
.section-sidenav.filtros-busca .componente-filtro .range-composto{
    padding:40px 0
}
.section-sidenav.filtros-busca .componente-filtro .range-composto div.container-range:last-child{
    margin-top:-28px
}
.section-sidenav.filtros-busca .componente-filtro .range-composto div.container-range:last-child input[type=range]{
    background-color:transparent;
    height:0
}
.section-sidenav.filtros-busca .componente-filtro .range-composto div.container-range:last-child input[type=range]::-moz-range-track{
    background-color:transparent
}
.section-sidenav.filtros-busca .componente-filtro .range-composto div{
    width:100%
}
@media screen and (max-width:576px){
    .section-sidenav.filtros-busca .componente-filtro .range-composto div{
        width:80%!important
    }
}
.section-sidenav.filtros-busca .componente-filtro .range-composto div input[type=range]{
    background-color:#c4c4c4;
    border:none;
    border-radius:10px;
    box-shadow:none!important;
    height:8px;
    max-width:100%;
    outline:none;
    padding:0!important;
    -webkit-appearance:none
}
.section-sidenav.filtros-busca .componente-filtro .range-composto div input[type=range]::-moz-range-thumb{
    background-color:#42949e;
    border:none;
    border-radius:10px;
    height:22px;
    pointer-events:all;
    position:relative;
    z-index:5;
    width:22px;
    -webkit-appearance:none
}
.section-sidenav.filtros-busca .componente-filtro .range-composto div input[type=range]::-webkit-slider-thumb{
    background-color:#42949e;
    border:none;
    border-radius:10px;
    height:22px;
    pointer-events:all;
    position:relative;
    z-index:1;
    width:22px;
    -webkit-appearance:none
}
.section-sidenav.filtros-busca .componente-filtro .range-composto div input[type=range]::-ms-thumb{
    background-color:#42949e;
    border:none;
    border-radius:10px;
    height:22px;
    pointer-events:all;
    position:relative;
    z-index:1;
    width:22px;
    -webkit-appearance:none
}
.section-sidenav.filtros-busca .componente-filtro .range-composto div input[type=range]+span{
    background-color:#42949e;
    color:#fff;
    bottom:55px;
    float:right;
    font-size:14px;
    font-weight:700;
    height:28px;
    padding:4px 0;
    position:relative;
    width:45px
}
.section-sidenav.filtros-busca .componente-filtro .range-composto div input[type=range]+span.primeiro-range{
    float:left
}
.section-sidenav.mostra-filtros{
    overflow: hidden;
    transform:translateX(-90%)
}
@media screen and (max-width:576px){
    .section-sidenav{
        transform:translateX(-100%);
        border-right:0;
        padding:104px 30px 0 40px!important;
        width:100%;
        z-index:1
    }
    .section-sidenav.mostra-filtros{
        transform:translateX(0)
    }
}
.section-content{
    display:inline-block;
    float:right;
    height:100vh;
    overflow:hidden;
    scrollbar-width: none;
    width:75%;
    transition: .3s ease-in-out;
}
.section-content.resultado-busca{
    padding:57px 0 10px 0;
    /* overflow:hidden; */
    position:relative;
    top:0;
}
@media screen and (max-width:575px){
    .section-content.resultado-busca{
        padding:0 16px 30px!important
    }
}
.section-content.resultado-busca>div:first-child{
    margin-bottom:3px
}
@media screen and (min-width:577px){
    .section-content.resultado-busca.mostra-filtros{
        width:97.45%
    }
}
.section-content .qtdd-resultados{
    font-family:Montserrat;
    color:#47a5ae;
    font-size:14px;
    padding:0 60px 0 30px
}
@media screen and (max-width:575px){
    .section-content .qtdd-resultados{
        margin-top:-72px
    }
}
@media screen and (max-width:576px){
    .section-content{
        width:100%
    }
}
.section-topo-cadastro{
    background-color:#0082a5;
    padding:90px 0 0;
    overflow:hidden
}
.section-topo-cadastro h1{
    font-family:Montserrat;
    font-size:32px
}
.section-topo-cadastro h1,.section-topo-cadastro p{
    color:#fff
}
.section-topo-cadastro p{
    font-size:18px
}
.section-topo-cadastro .ilustrativa svg{
    margin-bottom:-65%
}
@media screen and (max-width:576px){
    .section-topo-cadastro{
        padding:90px 0 15px
    }
    .section-topo-cadastro .ilustrativa{
        display:none
    }
    .section-topo-cadastro h1,.section-topo-cadastro p{
        text-align:center
    }
}
.section-cadastro-resumo{
    padding:60px 0 45px
}
.section-descricao-itens{
    margin-bottom:45px
}
.section-descricao-itens .col-lg-4{
    margin-left:-15px!important;
    padding:0;
    float:left
}
.section-descricao-itens .item:not(:last-child){
    margin-bottom:15px
}
.section-descricao-itens b{
    font-family:Montserrat;
    font-size:18px;
    color:#42949e;
    font-weight:400
}
.section-descricao-itens p{
    font-size:14px;
    margin:0
}
.section-descricao-itens svg{
    width:43px
}
.section-credenciado-gratis{
    padding:0
}
.section-credenciado-gratis b{
    font-family:Montserrat;
    font-size:18px;
    color:#42949e;
    font-weight:400
}
.section-credenciado-gratis b strong{
    font-weight:700
}
.section-credenciado-gratis ul{
    padding-left:0;
    padding-right:0
}
.section-credenciado-gratis ul li{
    margin:15px 0;
    padding-left:24px;
    /* background:url(../../images/list-marker.svg) no-repeat left top 2px */
}
.section-credenciado-gratis .link-cadastro{
    margin-top:24px
}
@media screen and (max-width:576px){
    .section-credenciado-gratis b{
        text-align:center;
        display:block;
        color:#818181
    }
    .section-credenciado-gratis .link-cadastro{
        text-align:center
    }
}
.section-form-cadastro{
    padding:30px 0;
    text-align:right
}
.section-form-cadastro .container-form{
    border:1px solid #f2f2f2;
    padding:60px 45px;
    display:inline-block;
    width:90%
}
.section-form-cadastro .container-form form[hidden]{
    display:none
}
@media screen and (max-width:576px){
    .section-form-cadastro .container-form{
        width:100%;
        border:0;
        padding:60px 0
    }
}
.section-form-cadastro .container-form h6{
    text-align:center
}
.section-form-cadastro .container-form p:not(.pilula){
    text-align:left
}
.section-form-cadastro .container-form p:not(.pilula) a{
    cursor:pointer
}
.section-form-cadastro .container-form button{
    display:block;
    text-align:left
}
.section-form-cadastro #mensagem-sucesso,.section-form-cadastro #mensagem-sucesso p{
    text-align:center
}
.section-form-cadastro #mensagem-sucesso svg{
    display:inline-block;
    max-width:100%
}
.section-form-cadastro #mensagem-sucesso .aviao-svg svg{
    width:160px;
    margin-top:72px;
    margin-bottom:40px
}
#btn-carregar-mais-resultados{
    width:180px;
    margin-top:15px
}
.div-chip-filial-credenciado{
    background:#47a5ae;
    border-radius:4px;
    color:#fff;
    padding:0 10px;
    position:absolute;
    right:0!important;
    text-transform:uppercase;
    top:-40px
}
.empresa-visualizacao-individual{
    border:1px solid #c2c2c2;
    border-radius:4px;
    height:330px;
    margin-bottom:30px;
    background-image:linear-gradient(180deg,#f2f2f2 30%,#fff 0);
    width:100%;
    padding:15px
}
@media screen and (max-width:575px){
    .empresa-visualizacao-individual{
        background-color:#f1f1f1;
        margin-top:20px;
        margin-bottom:0
    }
}
.empresa-visualizacao-individual[hidden]{
    display:none!important
}
.empresa-visualizacao-individual>div{
    margin-top:35px
}
.empresa-visualizacao-individual>div .nome-empresa{
    color:#42949e;
    display:block;
    font-family:Montserrat;
    font-size:14px;
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap
}
.empresa-visualizacao-individual>div .nome-empresa-card{
    color:#42949e;
    display:block;
    font-family:Montserrat;
    font-size:14px;
    text-transform:uppercase
}
.empresa-visualizacao-individual>div .cidade-empresa{
    color:#5c5c5c;
    display:block;
    font-family:Montserrat;
    padding:0 0 20px;
    text-transform:capitalize
}
.empresa-visualizacao-individual>div .logo-empresa{
    background-color:#fff;
    bottom:45px;
    min-height:90px;
    max-height:90px;
    line-height:90px;
    width:90px;
    display:inline-block;
    border:1px solid #c2c2c2;
    border-radius:4px;
    overflow:hidden;
    padding: 2px;
}

.empresa-visualizacao-individual>div .logo-empresa.img-container svg {
    height: 44px;
    vertical-align: middle;
}
.empresa-visualizacao-individual>div .logo-empresa.img-container img{
    width:100%;
    height:100%;
    -o-object-fit:contain;
    object-fit:contain;
    -o-object-position:center;
    object-position:center
}
.empresa-visualizacao-mapa{
    border-radius: 4px;
    cursor: pointer;
    padding:10px
}
.empresa-visualizacao-mapa [data-tooltip]:before {
    background-color: red;
    left: 15%;
}
.empresa-visualizacao-mapa [data-tooltip]:after {
    left: 15%;
}
@media screen and (min-width:577px){
    .empresa-visualizacao-mapa{
        margin-left:-25px;
        margin-bottom:5px
    }
}
@media screen and (max-width:575px){
    .empresa-visualizacao-mapa{
        margin-top:20px
    }
}
.empresa-visualizacao-mapa .logo-empresa{
    background-color:#fff;
    border-radius:4px;
    border:1px solid #bcbcbc;
    display:inline-block;
    line-height: 90px;
    min-height:90px;
    max-height:90px;
    width:90px;
    overflow:hidden;
    padding:2px
}

.empresa-visualizacao-mapa .logo-empresa.img-container svg{
    height: 44px;
    vertical-align: middle;
}
.empresa-visualizacao-mapa .logo-empresa.img-container img{
    max-width:75px;
    height:100%;
    -o-object-fit:contain;
    object-fit:contain;
    -o-object-position:center;
    object-position:center
}
.empresa-visualizacao-mapa .dados-empresa{
    display:inline-block;
    padding-left:8px;
    padding-right:0!important;
    vertical-align:top
}
.empresa-visualizacao-mapa .dados-empresa .nome-empresa{
    color:#42949e;
    display:block;
    font-size:14px;
    font-weight:700;
    margin-bottom:10px!important;
    margin-top:0!important;
    text-transform:uppercase
}
.empresa-visualizacao-mapa .dados-empresa .nome-empresa .div-chip-filial-credenciado{
    right:10px!important;
    top:0
}
.empresa-visualizacao-mapa .dados-empresa .nome-empresa span.truncate.filial{
    width:80%
}
.empresa-visualizacao-mapa .dados-empresa .nome-empresa[data-tooltip-left]:before{
    width:auto;
    max-width:100%
}
.empresa-visualizacao-mapa .dados-empresa .cidade-empresa{
    color:#5c5c5c;
    display:block;
    margin-top:-5px;
    padding:0 0 20px;
    text-transform:capitalize
}
.empresa-visualizacao-mapa .dados-empresa a{
    margin-top:2px!important
}
.empresa-visualizacao-mapa button{
    display:none;
    position: absolute;
    right: 10px;
}
.empresa-visualizacao-mapa:hover button{
    display:inline-block
}
.cidade-empresa svg{
    fill:#c2c2c2;
    height:16px;
    margin-right:6px;
    vertical-align:text-top
}
.card-icns-conveniencias{
    vertical-align:middle
}
.card-icns-conveniencias svg{
    margin-right:8px;
}
.mapa-resultados{
    background-color:#f2f2f2;
    border-radius:4px;
    height:calc(100% - 190px);
    padding: 0;
    position:fixed!important;
    right:0;
    top:190px;
    width:37%;
    z-index:500;
    transition:.3s ease-in-out;
}
.mapa-resultados>div:first-child{
    position: initial;
}
.mapa-resultados.ampliar-mapa{
    width:45%
}
@media screen and (max-width:575px){
    .mapa-resultados{
        height:45vh;
        margin-top:15px
    }
}
.pg-busca-socnet{
    height:100vh;
    overflow-y:hidden !important;
    width:100%
}

#div-mapa-localizacao {
    position: relative !important;
}

.titulo-rede-credenciada {
    background-color: white;
    border-bottom: 1px solid #c2c2c2;
    color: #42949e;
    font-size: 24px;
    font-weight: 700;
    padding: 10px 0 10px 34px;
    position: fixed;
    width: 100%;
    z-index: 999;
}

.pg-busca-socnet .topo-fixo-pesquisa{
    position:-webkit-sticky;
    position:sticky;
    top: 0;
    margin-top:-1px;
    z-index:999;
    background-color:#fff;
    padding:25px 30px 15px;
    box-shadow:0 8px 19px 0 rgba(50,50,50,.1)
}
@media screen and (max-width:575px){
    .pg-busca-socnet .topo-fixo-pesquisa{
        padding:15px 0
    }
}
.pg-busca-socnet .topo-fixo-pesquisa div.principal{
    margin-bottom: 10px;
}
.pg-busca-socnet #conteudo-resultados{
    padding:0 0 0 0 !important
}
.pg-busca-socnet #conteudo-resultados > div.estrutura-card{
    scrollbar-width: thin;
    overflow-x: hidden !important;
    width: 100%;
}
.pg-busca-socnet #conteudo-resultados > div.estrutura-card::-webkit-scrollbar{
    width:0
}
.pg-busca-socnet #conteudo-resultados > div.estrutura-card::-webkit-scrollbar-track{
    box-shadow:none;
    border-radius:4px
}
.pg-busca-socnet #conteudo-resultados > div.estrutura-card::-webkit-scrollbar-thumb{
    background:transparent;
}
@media screen and (max-width:575px){
    .pg-busca-socnet #conteudo-resultados{
        padding:0
    }
}
[data-mensagem] a:hover svg>path{
    fill:#42949e
}
.clean-card{
    background-color:#fff;
    border-radius:4px;
    border:1px solid #f2f2f2;
    padding:25px
}
@media screen and (max-width:768px){
    .clean-card{
        margin-bottom:15px
    }
}
@media screen and (max-width:576px){
    .clean-card{
        margin-bottom:0;
        padding:15px 25px 25px
    }
    .clean-card h6{
        display:none
    }
    .clean-card ul{
        margin-top:0
    }
}
.clean-card h6{
    margin-top:0;
    margin-bottom:0
}
.clean-card ul{
    padding-left:0;
    padding-right:0
}
.clean-card ul li{
    margin:15px 0;
    padding-left:20px;
    /* background:url(../../images/list-marker.svg) no-repeat left top 2px */
}
.clean-card [data-btn]{
    width:100%
}
.aviso-descritivo{
    background-color:#0082a5;
    border-radius:4px;
    margin-top:30px;
    padding:10px 30px
}
.aviso-descritivo p{
    font-size:14px
}
.aviso-descritivo a{
    color:#fff;
    font-family:MontserratMedium
}
.aviso-descritivo>*{
    color:#fff
}
@media screen and (max-width:576px){
    .aviso-descritivo{
        padding:20px
    }
}
#socnet-cadastro{
    cursor:default
}
#socnet-cadastro .aviso-descritivo{
    margin-bottom:60px
}
[class*=col-].row{
    margin-left:-1px;
    margin-right:-1px
}
[class*=col-].row .row{
    margin-left:0;
    margin-right:0
}
@media screen and (max-width:576px){
    .infinite-scroll{
        margin-left:0;
        margin-right:0;
        width:100%;
        flex-wrap:nowrap
    }
}
@media screen and (max-width:575px){
    .div-toggle{
        margin-top:15px
    }
}
.btn-filtro{
    background-color:#fff;
    display: grid;
    height:42px;
    max-width:42px;
    position:absolute;
    right:15px;
    top: 80px;
    z-index: 1001;
}
.btn-filtro svg{
    height:20px;
    left:-50%;
    position:relative;
    top:2px;
    width: auto !important;
}

.btn-filtro svg path{
    fill:#42949e
}

@media screen and (min-width:577px){
    .btn-filtro{
        right:unset;
        left:23.5%;
        position:fixed;
        border:1px solid #c2c2c2;
        border-radius:50%
    }
    .btn-filtro svg{
        top:1px;    
        left:-30%;
    }
    .btn-filtro.margin-left{
        left:1%
    }
    .btn-filtro:active,.btn-filtro:focus,.btn-filtro:hover{
        background-color:#fff
    }
}
.estrutura-resultados-mapa{
    margin-left: 20px;
    max-height:100%;
    padding:0!important;
    position:relative;
    /* width:53% */
}

@media screen and (max-width: 1920px){
    .estrutura-resultados-mapa,
    #topo-resultados-busca.visualizacao-mapa{
        width: 620px;
    }
    .mapa-resultados.ampliar-mapa {
        width: 58%;
    }
}
@media screen and (max-width: 1680px){
    .estrutura-resultados-mapa,
    #topo-resultados-busca.visualizacao-mapa{
        max-width: 550px;
    }
}
@media screen and (max-width: 1440px){
    .estrutura-resultados-mapa, 
    #topo-resultados-busca.visualizacao-mapa{
        width: 525px;
    }
}
@media screen and (max-width: 1366px){
    .estrutura-resultados-mapa, 
    #topo-resultados-busca.visualizacao-mapa{
        width: 492px;
    }
}
@media screen and (max-width: 1280px){
    .estrutura-resultados-mapa, 
    #topo-resultados-busca.visualizacao-mapa{
        width: 455px;
    }
}
@media screen and (max-width: 1024px){
    .estrutura-resultados-mapa, 
    #topo-resultados-busca.visualizacao-mapa{
        width: 375px;
    }
}
.estrutura-resultados-mapa .empresa-dados.active,.estrutura-resultados-mapa .empresa-dados:focus,.estrutura-resultados-mapa .empresa-dados:hover{
    background-color:#f2f2f2
}
.estrutura-resultados-mapa::-webkit-scrollbar{
    width:0!important
}
#btn-filtro-busca-socnet{
    margin-bottom:30px!important
}
.ajusta-grid {
    margin-left: 15px;
}

.infowindow-maps{
    background-color: #fff;
    border-radius: 4px;
    bottom: 183px;
    box-shadow: 0px 0px 12px #818181;
    height: 140px;
    left: -85px;
    padding: 10px;
    position: relative;
    width: 170px;
}

.infowindow-maps:before {
    background-color: #fff;
    bottom: -4px;
    content: "";
    height: 8px;
    left: 46%;
    position: absolute;
    transform: rotate(45deg);
    width: 8px;
}

.infowindow-maps p{
    font-size:1em;
    color: #494949;
    text-transform: capitalize;
    margin: 10px 0;
}
.infowindow-maps label{
    color:#42949e
}
.infowindow-maps b{
    color:#42949e;
    text-transform: uppercase;
}
.infowindow-maps button{
    width: 100%;
}
.esconde-loading{
    display:none!important
}
.page-template-socnet-perfil-credenciado{
    overflow-x:hidden
}
@media screen and (max-width:575px){
    #socnet-perfil-credenciado{
        overflow-y:hidden;
        overflow-x:unset;
        width:100vw
    }
}
.corpo-perfil{
    width:100%
}
@media screen and (min-width:993px){
    .corpo-perfil>section{
        padding-right:30px!important
    }
}
#div-header-perfil{
    background-color:#0082a5;
    max-height:-webkit-max-content;
    max-height:-moz-max-content;
    max-height:max-content
}
@media screen and (max-width:575px){
    #div-header-perfil{
        height:250px
    }
}
#div-header-perfil .conteudo-header-perfil{
    margin-top:75px;
    padding-bottom:30px
}
#div-header-perfil .conteudo-header-perfil .retornar{
    margin-bottom:15px
}
#div-header-perfil .conteudo-header-perfil .retornar a{
    color:#fff;
    font-size:14px
}
#div-header-perfil .conteudo-header-perfil .retornar a:hover{
    text-decoration:none
}
#div-header-perfil .conteudo-header-perfil .retornar a svg{
    height:10px;
    margin-right:10px
}
@media screen and (max-width:575px){
    #div-header-perfil .conteudo-header-perfil{
        padding:0 15px 15px
    }
}
#div-header-perfil .conteudo-header-perfil .perfil-logo-empresa{
    background-color:#fff;
    border:1px solid #f2f2f2;
    border-radius:4px;
    display:inline-block;
    height:134px;
    width:134px;
    max-width:134px;
    overflow:hidden
}
#div-header-perfil .conteudo-header-perfil .perfil-logo-empresa img{
    width:100%;
    height:100%;
    -o-object-fit:contain;
    object-fit:contain;
    -o-object-position:center;
    object-position:center
}
#div-header-perfil .conteudo-header-perfil .perfil-logo-empresa svg {
    height: 70px;
    margin-top: 20%;
}
@media screen and (max-width:575px){
    #div-header-perfil .conteudo-header-perfil .perfil-logo-empresa{
        height:100px;
        width:100px
    }
}
#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa{
    color:#fff;
    cursor:context-menu;
    display:inline-block;
    margin-left:15px;
    position:absolute;
    width:50%
}
#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .nome-empresa{
    font-size:24px;
    text-transform:uppercase
}

#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .nome-empresa .detalhe-nome{
    font-size:14px;
}

@media screen and (max-width:992px){
    #div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .nome-empresa{
        width:60vw
    }
}
@media screen and (max-width:575px){
    #div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .nome-empresa{
        width:55vw
    }
}
@media screen and (max-width:575px){
    #div-header-perfil .conteudo-header-perfil .perfil-dados-empresa{
        width:60%;
        margin-top:-8px
    }
}
#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .cnpj-empresa,#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .nome-fantasia{
    font-size:14px;
    text-transform:uppercase
}
@media screen and (max-width:992px){
    #div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .cnpj-empresa,#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .nome-fantasia{
        width:60vw
    }
}
@media screen and (max-width:575px){
    #div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .cnpj-empresa,#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .nome-fantasia{
        width:55vw
    }
}
#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .redes-sociais-empresa{
    padding-top:30px
}
#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .redes-sociais-empresa a{
    cursor:pointer;
    margin-right:8px
}
#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .redes-sociais-empresa a.lnk-site-empresa svg{
    height:22px
}
#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .redes-sociais-empresa a.lnk-facebook-empresa svg,#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .redes-sociais-empresa a.lnk-instagram-empresa svg,#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .redes-sociais-empresa a.lnk-linkedin-empresa svg{
    height:21px
}
#div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .redes-sociais-empresa a.lnk-twitter-empresa svg{
    height:17px
}
@media screen and (max-width:575px){
    #div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .redes-sociais-empresa{
        padding-top:15px
    }
    #div-header-perfil .conteudo-header-perfil .perfil-dados-empresa .redes-sociais-empresa a{
        margin-right:15px
    }
}
.conteudo-central{
    padding:30px 40px 30px 0
}
.conteudo-central .col-12{
    padding-left:0!important;
    padding-right:0!important
}
.conteudo-central .row .row{
    padding-left:0;
    padding-right:0
}
@media screen and (max-width:575px){
    .conteudo-central{
        padding:15px 0;
        border-top:.5px solid #c2c2c2
    }
}
.conteudo-central:not(:first-child){
    border-top:.5px solid #c2c2c2
}
.conteudo-central span{
    font-size:16px
}
.conteudo-central span.multicolumn{
    padding:0
}
@media screen and (min-width:575px){
    .conteudo-central span.multicolumn{
        column-count:3;
        -moz-column-count:3;
        column-gap:20px;
        padding:0 15px
    }
}
.conteudo-central span p{
    margin-top:0!important
}
.conteudo-central span p i.fa{
    margin-top:3px;
    padding:0
}
.conteudo-central.info-empresa {
    max-height: 100%;
}
.conteudo-central.info-empresa span{
    padding:0
}
.conteudo-central.info-empresa .multiline-truncate{
    position:relative;
    height:auto
}
.conteudo-central.info-empresa .multiline-truncate.truncated{
    height:8.75em;
    overflow:hidden
}
.conteudo-central.info-empresa .multiline-truncate.truncated:after{
    content:"";
    text-align:right;
    position:absolute;
    bottom:0;
    right:0;
    width:100%;
    height:1.2em;
    background:linear-gradient(180deg,hsla(0,0%,100%,0),#fff 60%)
}
.conteudo-central.info-empresa .toggle-text{
    color:#42949e;
    cursor:pointer;
    margin-top: 10px;
}
.conteudo-central.imagens-empresa img{
    border-radius:4px;
    height:390px;
    -o-object-fit:cover;
    object-fit:cover;
    width:100%
}
@media screen and (max-width:575px){
    .conteudo-central.imagens-empresa .item{
        position:relative
    }
}
#sidebar-perfil{
    background-color:#fff;
    border-radius:4px;
    cursor:context-menu;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
    margin-bottom:30px;
    padding:10px
}
@media screen and (min-width:1023px){
    #sidebar-perfil{
        max-width:390px
    }
}
@media screen and (min-width:993px){
    #sidebar-perfil{
        border:1px solid #c2c2c2;
        margin-top:-160px
    }
}
#sidebar-perfil .modal-content{
    height:600px;
    overflow:hidden!important;
    padding:20px!important
}
#sidebar-perfil svg{
    display:inline-block;
    margin:auto;
    position:relative
}
#sidebar-perfil .mapa-localizacao-empresa{
    background-image:linear-gradient(90deg,#005087,#1faeb2);
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    height:230px;
    margin:-10px;
    margin-bottom:15px
}
@media screen and (max-width:575px){
    #sidebar-perfil .mapa-localizacao-empresa{
        border-radius:0;
        margin:0 -30px;
        width:100vw
    }
}
@media screen and (max-width:575px){
    #sidebar-perfil .email-empresa,#sidebar-perfil .endereco-empresa,#sidebar-perfil .estados-empresa,#sidebar-perfil .filiais-empresa,#sidebar-perfil .horario-atendimento,#sidebar-perfil .site-empresa,#sidebar-perfil .telefone-empresa{
        padding-left:0!important
    }
}
#sidebar-perfil .endereco-empresa,#sidebar-perfil .site-empresa{
    margin:15px 0
}
#sidebar-perfil .endereco-empresa svg,
#sidebar-perfil .site-empresa svg{
    height:18px;
    margin-top:1px
}
#sidebar-perfil .endereco-empresa svg path {
    fill:rgb(31, 174, 178);
}
#sidebar-perfil .email-empresa{
    margin:15px 0
}
#sidebar-perfil .email-empresa svg{
    height:15px;
}
#sidebar-perfil .email-empresa svg path{
    fill: rgb(31, 174, 178);
}
#sidebar-perfil .telefone-empresa{
    margin:15px 0
}
#sidebar-perfil .telefone-empresa svg{
    height:18px;
    vertical-align: sub;
}
#sidebar-perfil .telefone-empresa svg path{
    fill: rgb(31, 174, 178);
}
#sidebar-perfil .telefone-empresa span{
    display:block
}
#sidebar-perfil .horario-atendimento{
    margin:15px 0
}
#sidebar-perfil .horario-atendimento svg{
    height:18px;
    margin-right: 4px;
    vertical-align: sub;
}
#sidebar-perfil .horario-atendimento svg path{
    fill: rgb(31, 174, 178);
}
#sidebar-perfil .filiais-empresa{
    cursor:pointer;
    margin:15px 0
}
@media screen and (min-width:575px){
    #sidebar-perfil .filiais-empresa p:not(last-child){
        margin-bottom:15px
    }
}
#sidebar-perfil .filiais-empresa svg{
    display:inline-block!important;
    height:16px;
    margin-left:5px;
    vertical-align:middle
}
#sidebar-perfil .estados-empresa{
    cursor:pointer;
    margin:15px 0
}
#sidebar-perfil .estados-empresa div:first-child{
    padding:0
}
#sidebar-perfil .estados-empresa div:first-child svg{
    height:34px;
    margin-top:6px
}
#sidebar-perfil .estados-empresa div:last-child{
    padding-left:22px
}
#sidebar-perfil .estados-empresa div:last-child svg{
    display:inline-block!important;
    height:16px;
    margin-left:5px;
    vertical-align:middle
}
#sidebar-perfil hr{
    background-color:#c2c2c2;
    border:.5px solid transparent;
    margin:0 15px
}
@media screen and (max-width:575px){
    #sidebar-perfil hr{
        margin:0
    }
}
#sidebar-perfil .txt-principal{
    padding-bottom:0
}
@media screen and (max-width:575px){
    #sidebar-perfil{
        border:none;
        border-radius:0;
        margin-top:0;
        margin-bottom:0;
        max-width:none;
        right:0;
        padding:0
    }
    #sidebar-perfil [data-modal]{
        padding:0;
        z-index:30px
    }
    #sidebar-perfil [data-modal] [data-cell]{
        padding-top:0
    }
    #sidebar-perfil [data-modal] [data-cell] a{
        top:90px
    }
    #sidebar-perfil [data-modal] [data-cell] .modal-content{
        height:calc(100vh - 56px);
        margin-top:56px;
        max-height:unset
    }
}
.estados-empresa .txt-principal{
    white-space:nowrap
}
.conjunto-dados{
    margin-left:15px;
    padding:0
}
.conjunto-dados p{
    margin:0
}
.txt-principal,.txt-secundario{
    font-size:16px
}
@media screen and (max-width:575px){
    .txt-principal,.txt-secundario{
        padding:0
    }
}
.txt-principal{
    color:#1faeb2;
    font-weight:700;
    margin:0 0 5px;
    padding-bottom:10px
}
.txt-principal.tag{
    font-size:12px;
    margin-bottom:10px;
    text-transform:uppercase
}
.txt-secundario{
    color:#818181;
    margin:0 0 2px;
    text-transform:capitalize
}
.txt-secundario.small{
    font-size:14px!important
}
#lista-enderecos-empresa{
    height:65%;
    margin-top:5px!important;
    overflow-y:auto;
    overflow-x:hidden;
    scrollbar-width: thin;
}
@media screen and (max-width:575px){
    #lista-enderecos-empresa{
        height:70%
    }
}
*::-webkit-scrollbar{
    width:4px
}
*::-webkit-scrollbar-track{
    box-shadow:inset 0 0 5px #f2f2f2;
    border-radius:4px
}
*::-webkit-scrollbar-thumb{
    background:#c2c2c2;
    border-radius:4px
}
#lista-enderecos-empresa.multicolumn{
    column-count:2;
    -moz-column-count:2;
    column-gap:20px;
    column-fill:auto
}
#lista-enderecos-empresa li{
    padding:15px 0
}
#lista-enderecos-empresa li.item-estados{
    border-bottom:0!important;
    padding:5px 0
}
#lista-enderecos-empresa li:not(:last-child){
    border-bottom:1px solid #f2f2f2
}
#lista-enderecos-empresa p{
    margin: 0;
    padding: 0;
}
#lista-enderecos-empresa li .informacoes-adicionais .txt-secundario{
    float:left;
    margin:0 0 8px!important;
    width:inherit;
    text-transform: lowercase;
}
#lista-enderecos-empresa li .informacoes-adicionais p{
    font-size: 14px !important;
}
@media screen and (max-width:575px){
    #lista-enderecos-empresa li .informacoes-adicionais .txt-secundario{
        float:none
    }
}
#lista-enderecos-empresa li .informacoes-adicionais .txt-secundario svg{
    margin:2px 10px 0 0!important;
    width: 16px;
    height: 16px;
}
@media screen and (max-width:575px){
    #lista-enderecos-empresa li .informacoes-adicionais{
        margin-top:8px
    }
}
.truncate{
    display:block;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
}
.filtro-km{
    cursor:pointer;
    margin-left:0!important;
    margin-right:0!important;
    margin-top:6px
}
.filtro-km span.alteracao{
    color:#42949e;
    float:right;
    font-family:MontserratSemiBold
}
.filtro-km button{
    background-color:#fff;
    border:1px solid #42949e;
    color:#42949e;
    height:30px!important;
    line-height:0!important;
    margin:5px 0
}
.filtro-km button.active,.filtro-km button:hover{
    background-color:#42949e!important;
    color:#fff!important
}
.filtro-km button:focus{
    box-shadow:none!important
}
.filtro-km.disabled{
    background-color:#f2f2f2;
    cursor:default
}
.filtro-km.disabled span:last-child{
    color:#c2c2c2
}
.filtro-km.div-opcoes{
    border-top-left-radius:0;
    border-top-right-radius:0;
    margin-right:40px!important;
    margin-top:-4px!important;
    max-width:100%;
    position:absolute;
    z-index:1
}
.chip{
    border-radius:44px;
    background-color:#fff;
    padding:6px 8px;
    color:#c2c2c2;
    border:1px solid #c2c2c2;
    display:inline-block;
    margin-right:10px;
    margin-top:12px;
    line-height:17px
}
.chip .excluir{
    cursor:pointer;
    margin-left: 5px;
}
.chip .excluir svg{
    height:10px;
    vertical-align: initial; 
    width:10px;
}
.chip .excluir svg path{
    fill:#c2c2c2
}
.aviso-toast{
    background-color:#5c5c5c;
    border-radius:4px;
    color:#fff;
    cursor:default;
    padding:15px;
    font-size:medium;
    position:fixed;
    right:30px;
    top:83px;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    z-index:5
}
.aviso-toast .fechar{
    cursor:pointer;
    margin-left:10px
}
.aviso-toast .fechar svg{
    vertical-align:middle;
    height:15px;
    width:15px
}
.aviso-toast .fechar svg path{
    fill:#fff
}
#msg-sem-resultados{
    margin-top: 20vh;
}
.autocomplete-maps-container {
    background-color: white;
    box-shadow: 0 3px 8px 0 rgba(0,0,0,.5);
    display: none;
    padding: 10px;
    position: absolute;
    top: 100%;
    width: 50%;
    z-index: 9999;
}
.autocomplete-maps-container .opcao-autocomplete-maps {
    cursor: pointer;
    padding: 8px 3px;
}
.autocomplete-maps-container .opcao-autocomplete-maps:not(:last-child) {
    border-bottom: 1px solid #c2c2c2;
}
.autocomplete-maps-container .opcao-autocomplete-maps.active {
    color: #42949e;
    font-weight: 700;
}
.autocomplete-maps-container .opcao-autocomplete-maps.active path{
    fill: #42949e;
}
.autocomplete-maps-container .opcao-autocomplete-maps svg {
    height: 15px;
    margin-right: 5px;
    vertical-align: text-top;
    width: 15px;
}
.autocomplete-maps-container .opcao-autocomplete-maps svg path {
    fill: #c2c2c2;
}
#ipt-busca-credenciado-2[aria-expanded="true"]+.autocomplete-maps-container {
    display: block;
}
#div-perfil-empresa{
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}
div[data-chips]{
    max-height: 90px;
    overflow-y: auto;
    scrollbar-width: thin;
}
.icn-mapa {
    bottom: 35px;
    left:-15px;
    position: relative;
}
.icn-mapa svg {
    height: 35px;
    width: auto !important;
}
.icn-mapa svg path{
    fill: #42949e;
    stroke: #fff;
}
.icn-mapa:hover{
    cursor: pointer;
}
.icn-mapa:hover svg path,
.icn-mapa.active svg path{
    fill: #FFC20E;
}